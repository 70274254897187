<template>
  <div class="seller">
    <sellerFilterCont
      :params="params"
      :getData="getData"
      :setParams="setParams"
    />
    <sellerTag
      :totalParams="totalParams"
      :params="params"
      :currency="currency"
    />
    <sellerTable
      :params="params"
      :dataList="list"
      :setParams="setParams"
      :getList="getData"
      :listTotal="listTotal"
      :setSpinning="setSpinning"
    />
  </div>
</template>
<script setup>
  import { ref, reactive, computed, onMounted, watch } from "vue";
  import { financeApi } from "~/server/operator/finance";
  import sellerFilterCont from './sellerFilterCont';
  import { AMAZON_CURRENCY_MAPPER } from "~/contants/common";
  import sellerTag from './sellerTag';
  import sellerTable from './sellerTable';
  import moment from 'moment';
  
  const props = defineProps(['setSpinning']);

  const list = ref([]);
  const listTotal = ref(0);
  const totalParams = ref({
    totalAmount: 0,
    totalPaidAmount: 0,
    totalPendingAmount: 0,
    totalPlatformFeeAmount: 0
  })

  const params = ref({
    marketplace: "amazon.com",
    startBillingDateStr: moment().subtract(31, "day").valueOf(),
    endBillingDateStr: moment().valueOf(),
    page: 1,
    size: 10,
    status: null, // not_paid paid delayed pending
  });

  const currency = computed(() => {
    const data = AMAZON_CURRENCY_MAPPER[params.value.marketplace];
    return data;
  })

  watch(() => params.value.marketplace, () => {
    getData();
  })

  const setParams = data => {
    if (data) {
      params.value = data;
    } else {
      params.value = {
        marketplace: "amazon.com",
        startBillingDateStr: moment().subtract(31, "day").valueOf(),
        endBillingDateStr: moment().valueOf(),
        page: 1,
        size: 10,
        status: null
      };
      getData();
    }
  }

  const getData = async () => {
    props.setSpinning(true);
    const paramsData = {...params.value};
    paramsData.startBillingDateStr = `${moment(paramsData.startBillingDateStr).format('YYYY-MM')}-01`;
    paramsData.endBillingDateStr = `${moment(paramsData.endBillingDateStr).format('YYYY-MM')}-01`;
    const billingsRes = await financeApi.getFinancePublisherBillings(paramsData);
    const aggRes = await financeApi.getFinancePublisherBillingsAgg(paramsData);
    const { data, total } = billingsRes;
    if (data) {
      list.value = data;
      listTotal.value = total;
    }
    if (aggRes) {
      totalParams.value = aggRes;
    }
    props.setSpinning(false);
  };

  onMounted(() => {
    getData();
  })

</script>
<style lang="less" scoped>

</style>
