<template>
  <div class="table" ref="tableRef">
    <artmisads-table :columns="columns" row-key="id" :data-source="props.dataList" :pagination="{
      total: props.listTotal,
      current: props.params.page,
      pageSize: props.params.size,
      showSizeChanger: true
    }" :change="onChangePage" tableLayout="fixed">
      <template #bodyCell="{ column, record, text }">
        <template v-if="column.name === 'showCurrency'">
          {{ AMAZON_CURRENCY_MAPPER[record.marketplace] }}{{ text }}
        </template>
        <template v-if="column.name === 'status'">
          {{ FINANCE_SELLER_STATUS_MAP[text].label }}
        </template>
        <template v-if="column.dataIndex === 'pendingAmount'">
          {{ AMAZON_CURRENCY_MAPPER[record.marketplace] }}{{ (record.readyToPayAmount + record.pendingAmount).toFixed(2) }}
        </template>
        <template v-if="column.name === 'billingDateTimestamp'">
          {{ moment(record.billingDateTimestamp).format('YYYY-MM') }}
        </template>
        <template v-if="column.name === 'actions'">
          <div class="actions">
            <artmisads-select-button @click="() => toDetail(record.id)" size="small">Details</artmisads-select-button>
            <artmisads-select-button class="confirm-btn" v-if="record.status === 'pending'"
              @click="() => setShowConfirmModal(record.id)" size="small">Confirm</artmisads-select-button>
            <artmisads-modal v-model:open="showConfirmModal" :width="500" title="Payment Confirm?" okText="Confirm"
              @ok="() => onChangeStatus()">
              <div class="active-product">
                <div class="title-ext">
                  Are you sure to send payments to all publishers?
                </div>
              </div>
            </artmisads-modal>
          </div>
        </template>
      </template>
    </artmisads-table>
  </div>
</template>
<script lang="jsx" setup>
import { useRouter } from "vue-router";
import { financeApi } from "~/server/operator/finance";
import moment from 'moment';
import { ref, computed } from 'vue';
import { message } from "ant-design-vue";
import showStatus from '~/pages/seller/Brands/components/showStatus.vue';
import { AMAZON_CURRENCY_MAPPER } from '~/contants/common';
import { FINANCE_SELLER_STATUS_MAP } from '~/contants/operator';

const router = useRouter();
const showConfirmModal = ref(false);
const tableRef = ref(null);
const props = defineProps(['listTotal', 'setParams', 'params', 'dataList', 'getList', 'setSpinning']);
const columns = [
  {
    title: 'Date',
    dataIndex: 'billingDateTimestamp',
    width: 140,
    name: 'billingDateTimestamp',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    width: 120,
    name: 'showCurrency',
  },
  {
    title: 'Paid',
    dataIndex: 'paidAmount',
    width: 150,
    name: 'showCurrency',
  },
  {
    title: 'Not Paid',
    dataIndex: 'pendingAmount',
    width: 200,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    name: 'status',
    width: 140,
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    name: 'actions',
    width: 150,
  },
]

const onChangeStatus = async () => {
  props.setSpinning(true)
  const res = await financeApi.financePublisherBillingsConfirm({ id: showConfirmModal.value });
  if (res === null) {
    message.success('Success');
    showConfirmModal.value = false;
    props.getList();
  }
  props.setSpinning(false)
}

const onChangePage = (data, filters, sorter) => {
  const { current, pageSize } = data;
  let newParams = { ...props.params };
  newParams.page = current;
  newParams.size = pageSize;
  if (sorter) {
    const { field, order } = sorter;
    newParams.sortField = field;
    newParams.sortDirection = order === 'ascend' ? 'asc' : 'desc';
  } else {
    newParams.sortField = '';
    newParams.sortDirection = '';
  }
  props.setParams(newParams);
  props.getList();
}

const setShowConfirmModal = id => {
  showConfirmModal.value = id || false;
}


const toDetail = id => {
  const { href } = router.resolve({ path: `/operator/finance/payments/publisher-detail/${id}` });
  window.open(href, '_blank');
}

</script>
<style lang="less" scoped>
.table {
  padding: 16px 0;

  :deep(.ant-tooltip-arrow) {
    display: none;
  }

  :deep(.ant-table) {
    .ant-table-thead {
      .ant-table-cell {
        line-height: 24px;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;

      button {
        align-self: center;
      }

      .confirm-btn {
        color: var(--primary-text);
        border-color: #fff;
        box-shadow: none;
      }
    }
  }
}
</style>