<template>
  <div class="payments">
    <a-spin :spinning="spinning">
      <breadcrumbs :breadcrumbsData="breadcrumbsData" />
      <div class="content">
        <div class="tabs">
          <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane key="seller" tab="Seller">
              <seller
                :setSpinning="setSpinning"
              />
            </a-tab-pane>
            <a-tab-pane key="publisher" tab="Publisher">
              <publisher
                :setSpinning="setSpinning"
              />
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script setup>
import { ref } from "vue";
import breadcrumbs from "../../breadcrumbs.vue";
import publisher from "./publisher/index.vue";
import seller from "./seller/index.vue";

const activeKey = ref('seller');
const spinning = ref(false);

const setSpinning = status => {
  spinning.value = status;
}

const breadcrumbsData = {
  title: "Payments",
  links: [
    {
      name: "Home",
      path: "/operator/users",
    },
    {
      name: "Finance",
      path: "/operator/finance/payments",
    },
    {
      name: "Payments",
      path: "/operator/finance/payments",
    },
  ],
};

</script>

<style lang="less" scoped>
.payments {
  padding: 0 16px;
  .content {
    overflow: hidden;
    border-radius: 12px;
    background-color: var(--color-white);
    box-shadow: var(--section-box-shadow-6);
    .tabs {
      padding: 16px;
    }
  }
}

</style>