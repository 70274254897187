<template>
  <div class="filter-wrap">
    <div class="filterBar" ref="marketplaceRef">
      <div class="filterBar-content">
        <div class="item">
          <span>Marketplace</span>
          <artmisads-select
            style="width: 232px"
            v-model:value="props.params.marketplace"
            :getPopupContainer="() => marketplaceRef"
            :options="COUNTRY_BASE_OPTIONS"
            @change="handleChangeMarketplace"
          />
        </div>
        <div class="filterBar-content">
          <div class="item">
            <span>Status</span>
            <artmisads-select
              style="width: 232px"
              v-model:value="props.params.status"
              :getPopupContainer="() => marketplaceRef"
              :filter-option="false"
              :not-found-content="null"
              :options="FINANCE_PUBLISHER_STATUS"
              placeholder="All"
            />
          </div>
          <div class="item">
            <span>Date</span>
            <artemisads-range-picker
              v-model:value="dateParams"
              allowClear
              @openChange="openChange"
              style="width: 232px"
              :disabled-date="disabledDate"
              picker="month"
            />
          </div>
        </div>
        <div class="btn">
          <artmisads-button
            @click="onSubmitSearch"
            :isSquare="true"
            type="primary"
            size="middle"
            >Search</artmisads-button
          >
          <artmisads-button
            @click="onReset"
            type="text"
            :isSquare="true"
            size="middle"
            >Reset</artmisads-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import moment from "moment";

import { ref, watch } from "vue";

import { COUNTRY_BASE } from "~/contants/common";
import { FINANCE_SELLER_STATUS } from "~/contants/operator";
import { publisherAdminApi } from "~/server/index";

import { RANGE_PRESETS_ADMIN_COMMISSIONS } from "~/contants/reports";
import Icon from "~/components/Icon.vue";
import { message } from "ant-design-vue";

const FINANCE_PUBLISHER_STATUS = FINANCE_SELLER_STATUS.filter(i => i.value !== 'delayed')
const COUNTRY_BASE_OPTIONS = COUNTRY_BASE.map((item) => {
  item.value = item.host;
  item.label = item.host;
  return item;
});

const dateParams = ref([moment().subtract(31, "day"), moment()]);
const marketplaceRef = ref(null);
const props = defineProps(["params", "setParams", "getData"]);

const onReset = () => {
  props.setParams();
  dateParams.value = [moment().subtract(31, "day"), moment()];
};

const disabledDate = (current) => {
  if (!dateParams.value || dateParams.value.length === 0) {
    return false;
  }
  const tooLate =
    dateParams.value[0] && current.diff(dateParams.value[0], "days") > 365;
  const tooEarly =
    dateParams.value[1] && dateParams.value[1].diff(current, "days") > 365;
  return tooEarly || tooLate;
};

const onSubmitSearch = () => {
  if (!dateParams.value) {
    return message.error("请选择日期范围");
  }
  props.getData();
};

const openChange = (status) => {
  if (!status && dateParams.value) {
    const dateTime = {
      startBillingDateStr: dateParams.value[0].valueOf(),
      endBillingDateStr: dateParams.value[1].valueOf(),
    };
    const newParams = { ...props.params, ...dateTime };
    props.setParams(newParams);
  }
};

const handleChangeMarketplace = marketplace => {
  const newParams = { ...props.params, marketplace };
  props.setParams(newParams);
};
</script>
<style lang="less" scoped>
.filter-wrap {
  .filterBar {
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #f8f7fc;
    border-radius: 8px;
    .filterBar-content {
      width: 100%;
      display: flex;
    }
  }
  .btn {
    display: flex;
    justify-content: right;
    button {
      margin-left: 15px;
    }
    > button {
      cursor: pointer;
      display: flex;
      margin-left: 16px;
      align-self: center;
      .shangchuan2 {
        width: 20px;
        height: 20px;
        fill: var(--dark-1);
        margin-right: 8px;
      }
      .jiantou {
        width: 16px;
        height: 16px;
        fill: var(--dark-3);
        margin-left: 8px;
      }
      .daochu {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        fill: var(--dark-1);
      }
      > svg {
        align-self: center;
      }
      > span {
        align-self: center;
      }
      .btn-text {
        align-self: center;
        font-weight: 500;
        line-height: 20px;
        margin-right: 8px;
      }
    }
  }
  .item {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    > span {
      color: var(--dark-2);
      font-weight: 500;
      font-size: 13px;
      letter-spacing: 0.3px;
      line-height: 18px;
      margin-bottom: 4px;
    }
  }
  .marketplace {
    display: flex;
    align-content: center;
    &:deep {
      .ant-select-selection-item,
      .ant-select-item-option-content {
        display: flex;
        align-items: center;
        .iconfont {
          margin-right: 4px;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
