
import { keyBy } from 'lodash';

export const COMMISSION_STATUS = [
  { label: 'Open', value: 'open' },
  { label: 'Locked', value: 'locked' },
  { label: 'DM Received', value: 'seller_payment_received' },
  { label: 'Pending Payment', value: 'pending_payment' },
  { label: 'Paid', value: 'paid' },
]

export const PRODUCT_STATUS = [
  { label: 'All', value: '' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
]

export const FINANCE_SELLER_STATUS = [
  { label: 'Pending', value: 'pending' },
  { label: 'Not Paid', value: 'not_paid' },
  { label: 'Paid', value: 'paid' },
  { label: 'Delayed', value: 'delayed' },
]

export const FINANCE_SELLER_STATUS_MAP = keyBy(FINANCE_SELLER_STATUS, 'value')

export const FINANCE_PAYMENT_DETAIL_STATUS = [
  { label: 'Pending', value: 'pending' },
  { label: 'Not Paid', value: 'not_paid' },
  { label: 'Paid', value: 'paid' },
  { label: 'Pending Review', value: 'paid_review' },
  { label: 'Delayed', value: 'delayed' },
  { label: 'Part Paid', value: 'part_paid' },
]

export const FINANCE_PAYMENT_DETAIL_STATUS_MAP = keyBy(FINANCE_PAYMENT_DETAIL_STATUS, 'value')

export const FINANCE_SELLER_PAYMENT_STATUS = [
  { label: 'Part Paid', value: 'part_paid' },
  { label: 'Not Paid', value: 'not_paid' },
  { label: 'Paid', value: 'paid' },
]

export const FINANCE_SELLER_PAYMENT_STATUS_MAP = keyBy(FINANCE_SELLER_PAYMENT_STATUS, 'value')

export const FINANCE_PAYMENT_PUBLISHER_DETAIL_STATUS = [
  { label: 'Not Paid', value: 'not_paid' },
  { label: 'Paid', value: 'paid' },
]

export const FINANCE_PAYMENT_PUBLISHER_DETAIL_STATUS_MAP = keyBy(FINANCE_PAYMENT_PUBLISHER_DETAIL_STATUS, 'value')