<template>
  <div class="tag">
    <div class="sales item">
      <span class="num">{{props.currency}}{{props.totalParams.totalAmount}}</span>
      <span class="name">Total Amount</span>
    </div>
    <div class="seller item">
      <span class="num">{{props.currency}}{{props.totalParams.totalPaidAmount}}</span>
      <span class="name">Seller Paid</span>
    </div>
    <div class="publishers item">
      <span class="num">{{props.currency}}{{props.totalParams.totalPendingAmount}}</span>
      <span class="name">Seller Pending</span>
    </div>
    <div class="fees item">
      <span class="num">{{props.currency}}{{props.totalParams.totalPaidPlatformFeeAmount}}</span>
      <span class="name">ArtemisAds Commission</span>
    </div>
  </div>
</template>
<script setup>
  import { AMAZON_CURRENCY_MAPPER } from '~/contants/common';
  const props = defineProps(['totalParams', 'currency']);
</script>
<style lang="less" scoped>
  .tag {
    display: flex;
    margin-top: 16px;
    .item {
      background: #F8F7FC;
      display: flex;
      flex-direction: column;
      padding: 7px 12px;
      color: var(--dark-1);
      text-align: center;
      width: 180px;
      margin-right: 20px;
      border-radius: 12px;
      .num {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
      .name {
        font-size: 12px;
        color: var(--dark-2);
        font-weight: 600;
        margin-top: 4px;
      }
      .filter {
        font-size: 12px;
        color: var(--dark-3);
      }
    }
  }
</style>